'use client';

import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';

import { cn, formatPercent } from '@streetferret/utils';

type ProgressProps = React.ComponentPropsWithoutRef<
  typeof ProgressPrimitive.Root
> & {
  label?: React.ReactNode;
  valueLabel?: React.ReactNode;
};
const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, label, valueLabel, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'relative h-8 w-full overflow-hidden rounded-md bg-secondary/50',
      className,
    )}
    {...props}>
    <div className="absolute z-10 flex items-center inset-0 px-2">{label}</div>
    <div className="absolute z-10 flex items-center justify-end inset-0 px-2 font-bold">
      {valueLabel ? valueLabel : formatPercent((value ?? 0) / 100)}
    </div>
    <ProgressPrimitive.Indicator
      className={cn('h-full w-full flex-1 transition-all bg-primary/80')}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
