import clsx from 'clsx';

export interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
}
export function Container({ children, className }: ContainerProps) {
  return (
    <div className={clsx('max-w-6xl px-4 mx-auto', className)}>{children}</div>
  );
}
