'use client';
import { Avatar } from '@streetferret/ui-web/avatar';
import { Container } from '@streetferret/ui-web/container';
import { motion } from 'framer-motion';
import Image from 'next/image';
import PaulHeadshot from './_media/paul-headshot-small.png';

export function About() {
  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="flex flex-col items-center gap-6">
        <motion.div className="flex flex-col items-center gap-3">
          <Avatar className="!h-40 !w-40">
            <Image src={PaulHeadshot} alt="Paul Johnson" placeholder="blur" />
          </Avatar>
          <motion.h3 transition={{ y: { stiffness: 1000, velocity: -100 } }}>
            {"Hi, I'm Paul."}
          </motion.h3>
        </motion.div>
        <motion.p className="text-left">
          In 2018 I commissioned as a Naval Officer and immediately went
          overseas forward-deployed to a ship homeported out of Rota, Spain
          where I lived for the next 3 years. While it led to some amazing
          opportunities, it was an extremely difficult tour that weighed heavily
          on myself, my peers, and our Sailors in more ways than many can
          imagine. Alcohol was certainly the coping mechanism of choice.
        </motion.p>
        <motion.p className="text-left">
          My next duty station took me to San Diego in 2021 and once removed
          from the constant stressful environment that was my first ship, I
          began noticing a lot of issues I had been dealing with: persistent
          sleep problems, nightmares, anxiety, depression... the list goes on.
          Alcohol remained a constant, but the narrative began shifting when one
          of my friends, a Marine Officer, asked me to train for and run the
          Marine Corps Marathon together in 2022.
        </motion.p>
        <motion.p className="text-left">
          We trained for 4-5 months and both ended up finishing the marathon
          under 3 hours and qualifying for the prestigious Boston Marathon.
          During that period of focused training I realized how much the running
          helped me cope with some of the symptoms I was experiencing with the
          anxiety and depression. While I tried to start addressing the issues
          it was time for my next station and I moved to Newport, RI at the very
          start of 2023.
        </motion.p>
        <motion.p className="text-left">
          January 2nd, 2023 I started a run streak and began running every day.
          This very quickly evolved into the ultra scene and I was running 100
          mile races by April. Running was my therapy. It gave me a release and
          escape from everything I was feeling and in a positive manner that
          contributed to my overall well-being, rather than detracting from it.
        </motion.p>
        <motion.p className="text-left">
          As both my running and social media presence developed I have done my
          best to share my journey but also inspire others to get up, engage in
          physical activity, and find their own positive outlets. The military
          and veteran communities are particularly prone to struggles with
          mental health due to the nature of everything they do and experience.
          Team RWB&#39;s mission to enrich veteran&#39;s lives helps us find
          purpose in sense of community and encourage and provide opportunities
          for everyone to engage in the power of physical activity while
          improving our mental health at the same time.
        </motion.p>
      </motion.div>
    </Container>
  );
}
