import { Instagram, Mail, Youtube } from 'lucide-react';
import { FaStrava } from 'react-icons/fa';

export const INSTAGRAM = {
  name: 'Instagram',
  href: 'https://www.instagram.com/pauijohnson/',
  icon: Instagram,
};
export const STRAVA = {
  name: 'Strava',
  href: 'https://www.strava.com/athletes/107251059',
  icon: FaStrava,
};
export const YOUTUBE = {
  name: 'Youtube',
  href: 'https://youtube.com/@pauijohnson',
  icon: Youtube,
};
export const EMAIL = {
  name: 'Email',
  href: 'mailto:Pauljohnson.run@gmail.com',
  icon: Mail,
};
export const social = [INSTAGRAM, STRAVA, YOUTUBE] as const;
