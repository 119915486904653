'use client';

import { BaseMap } from '@streetferret/map-web';
import { Avatar, AvatarFallback } from '@streetferret/ui-web/avatar';
import { cn } from '@streetferret/utils';
import { type Point, type Position } from 'geojson';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import { useState } from 'react';
import { Layer, Marker, Popup, Source } from 'react-map-gl';
import paulHeadshotSmall from '../_media/paul-headshot-small.png';

interface TransconMapProps {
  latestPosition?: { point: Point };
  children?: React.ReactNode;
  start: Position;
  end: Position;
}
export function TransconMap({
  latestPosition,
  children,
  start,
  end,
}: TransconMapProps) {
  const [popup, setPopup] = useState(false);
  const { resolvedTheme } = useTheme();

  return (
    <BaseMap
      baseTheme={resolvedTheme === 'dark' ? 'dark' : 'light'}
      type="base"
      initialViewState={{
        bounds: [
          [start[0], start[1]],
          [end[0], end[1]],
        ],
        fitBoundsOptions: {
          padding: 100,
        },
      }}
      onClick={e => console.log(e.lngLat)}
      styleDiffing={false}>
      <Source
        id="route"
        type="vector"
        url="https://d1zqyi8v6vm8p9.cloudfront.net/route_3179909856729752394.json">
        <Layer
          {...{
            id: 'route',
            type: 'line',
            source: 'route',
            'source-layer': 'route_3179909856729752394',
            paint: {
              'line-width': 1.6,
              'line-color': '#2463eb',
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
          }}
        />
      </Source>
      {latestPosition?.point ? (
        <>
          <Marker
            longitude={latestPosition.point.coordinates[0]}
            latitude={latestPosition.point.coordinates[1]}
            anchor="bottom"
            onClick={e => {
              e.originalEvent.stopPropagation();
              setPopup(true);
            }}>
            <div className="relative h-7 w-7">
              <div
                className={cn(
                  'absolute inset-0 animate-ping bg-red-500 rounded-full',
                  'hidden',
                )}
                style={{ animationDuration: '2s' }}
              />
              <Avatar className="border-2 !h-7 !w-7 cursor-pointer">
                <Image
                  src={paulHeadshotSmall}
                  alt="Paul"
                  className="h-full w-full"
                />
                <AvatarFallback>P</AvatarFallback>
              </Avatar>
            </div>
          </Marker>
        </>
      ) : null}
      {popup && latestPosition ? (
        <Popup
          anchor="top"
          longitude={latestPosition.point.coordinates[0]}
          latitude={latestPosition.point.coordinates[1]}
          closeButton={false}
          onClose={() => setPopup(false)}>
          <div className="">
            <div className="text-lg ">Transcon Complete</div>
            <div>
              <span>{latestPosition.point.coordinates[0]}</span>
              <span>, </span>
              <span>{latestPosition.point.coordinates[1]}</span>
            </div>
          </div>
        </Popup>
      ) : null}

      {children}
    </BaseMap>
  );
}
